import type { IListingCardData } from 'store/listing-list/types'

import { Box, Grid } from '@mui/material'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import Card, { CardActionArea } from '@rentspree/component-2023.components.organisms.card'
import CardElement from '@rentspree/component-2023.components.organisms.card-element'
import Pagination from '@rentspree/component-2023.components.organisms.pagination'
import { useRouter } from 'next/router'

import { Currency } from 'components/common/molecules/currency/currency'
import env from 'env'
import { useSelector } from 'store'
import { selectAgentProfileOwnerId } from 'store/agent-profile'
import {
  selectListingListForListingCard,
  selectListingListPagination,
} from 'store/listing-list/selectors'
import tracker, { events } from 'tracker'

import { parsePhotoGcsProtocol, resizePhoto } from '../../../utils/image-proxy/path'

type ActiveListingProps = {
  isDisplayFooter?: boolean
}
export const ActiveListing: React.FC<ActiveListingProps> = () => {
  const router = useRouter()
  const listings = useSelector(selectListingListForListingCard)
  const agentId = useSelector(selectAgentProfileOwnerId)
  const paginationData = useSelector(selectListingListPagination)
  const openLink =
    ({ url, listingId }: { url: string; listingId: string }) =>
    (e: React.MouseEvent) => {
      e.preventDefault()
      tracker?.trackEvent(events.PUBLIC_PAGE.CLICK_VIEW_LISTING_PAGE, {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        listing_id: listingId,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        agent_id: agentId,
      })
      window.open(url, '_self')
    }

  const onPageChange = (event: React.ChangeEvent<unknown>, current: number) => {
    // eslint-disable-next-line no-void
    void router.replace({ query: { ...router.query, listingPage: current } }, undefined, {
      scroll: false,
    })
  }

  const isShowPagination = paginationData.maxPage && paginationData.maxPage > 1

  const parsePhoto = (photo: string) =>
    resizePhoto(env.IMAGE_PROXY_HOST, parsePhotoGcsProtocol(photo))

  return (
    <Box className="active-listing">
      <Box className="active-listing-title-container">
        <Typography variant="title-medium">Active Listings ({paginationData.total})</Typography>
      </Box>
      {!paginationData.total ? (
        <Typography variant="body">There is no active listing yet.</Typography>
      ) : (
        <Box className="active-listing-cards-container">
          <Grid container spacing={2}>
            {listings.map((listing: IListingCardData) => (
              <Grid className="card-listing-grid" key={listing.id} item xs={12} sm={6} md={4}>
                <Card className="card-listing" variant="outlined" noPaddingTop>
                  <CardActionArea
                    className="card-listing-action"
                    onClick={openLink({ url: listing.publicListingUrl, listingId: listing.id })}
                  >
                    <img
                      className="card-listing-cover"
                      src={parsePhoto(listing.photo)}
                      alt="listing-card-cover"
                    />
                    <CardElement>
                      <Typography component="div" variant="title">
                        <Currency value={listing.rentAmount} />
                      </Typography>
                      <Typography
                        className="property-title-truncate card-title"
                        variant="body"
                        component="div"
                      >
                        {listing.propertyAddress}
                      </Typography>
                      <Typography
                        variant="body"
                        component="div"
                        className="property-title-truncate"
                      >
                        {listing.propertyType}&nbsp;
                        {listing.propertyDetail}
                      </Typography>
                    </CardElement>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
          {isShowPagination && (
            <Box className="cards-pagination">
              <Pagination
                data-testid="pagination-listing"
                count={paginationData.maxPage}
                page={paginationData.page}
                onChange={onPageChange}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}
