import { Box } from '@mui/material'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import React from 'react'

export type ComplimentItemProps = {
  compliment?: string
  total?: number
}
export type OverallComplimentProps = {
  compliments: ComplimentItemProps[]
}

export const OverallCompliment: React.FC<OverallComplimentProps> = ({
  compliments,
}: OverallComplimentProps) => {
  if (compliments.length === 0) {
    return null
  }
  return (
    <Box display="flex" gap="8px" flexDirection="column" alignItems="center">
      {compliments.map((item: ComplimentItemProps) => (
        <Box display="flex" flexDirection="row" justifyContent="space-between" width="220px">
          <Typography variant="body">{item.compliment}</Typography>
          <Typography variant="body">{item.total ?? 0}</Typography>
        </Box>
      ))}
    </Box>
  )
}
