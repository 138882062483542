import { Grid } from '@mui/material'
import Card from '@rentspree/component-2023.components.organisms.card'
import CardElement from '@rentspree/component-2023.components.organisms.card-element'

import { useSelector } from 'store'
import { selectAgentReviewSummary } from 'store/agent-review-summary/selectors'

import { OverallCompliment } from './overall-compliments/overall-compliments'
import { OverallRating } from './overall-rating/overall-rating'

export const SummaryReview: React.FC = () => {
  const summary = useSelector(selectAgentReviewSummary)
  const { compliments, average, totalReviews: total } = summary

  return (
    <Card variant="outlined" data-testid="review-summary-container">
      <CardElement>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <OverallRating average={average} total={total} />
          </Grid>
          <Grid item md={6} xs={12}>
            <OverallCompliment compliments={compliments} />
          </Grid>
        </Grid>
      </CardElement>
    </Card>
  )
}
