import { Box } from '@mui/material'
import { Link } from '@rentspree/component-2023.components.atoms.link'
import { Typography } from '@rentspree/component-2023.components.atoms.typography'

interface IFooterItemSection {
  headerText?: string
  listItem: IFooterLink[]
}

export const FooterItemSection: React.FC<IFooterItemSection> = ({ headerText, listItem }) => (
  <Box className="footer-section-container">
    <Typography className="footer-section-title" variant="body-large">
      {headerText}
    </Typography>
    <Box className="footer-section-item-container">
      {listItem.map(({ displayText, link, onClick }: IFooterLink) => (
        <Link
          key={`footer-section-item-${displayText}`}
          data-testid={`footer-section-item-${displayText}`}
          className="footer-section-item"
          variant="bodySmall"
          onClick={onClick}
          href={link}
          target="_blank"
          color="black"
        >
          {displayText}
        </Link>
      ))}
    </Box>
  </Box>
)
