import PropTypes from 'prop-types'

import { Span } from '../../atoms/typography/span'

type Props = ISpan & {
  value: number
}

export const Currency: React.FC<Props> = ({ value, ...style }) => (
  <Span {...style}> {`$ ${value.toLocaleString()}`}</Span>
)

Currency.propTypes = {
  value: PropTypes.number.isRequired,
}
