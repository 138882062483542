import { Box } from '@mui/material'
import Divider from '@rentspree/component-2023.components.atoms.divider'
import { Link } from '@rentspree/component-2023.components.atoms.link'
import { Typography } from '@rentspree/component-2023.components.atoms.typography'

interface IFooterSectionRightsReserved {
  listItem: IFooterLink[]
}

export const FooterSectionRightsReserved: React.FC<IFooterSectionRightsReserved> = ({
  listItem,
}) => (
  <Box className="rights-reserved-container">
    <Divider />
    <Box className="rights-reserved-row">
      <Typography className="rights-reserved-text" variant="body">
        © 2024 RentSpree. All rights reserved.
      </Typography>
      <Box className="bottom-menu-item-container">
        {listItem.map(({ displayText, link, onClick }: IFooterLink) => (
          <Link
            key={`footer-section-item-${displayText}`}
            className="footer-section-item"
            variant="bodySmall"
            onClick={onClick}
            href={link}
            target="_blank"
            color="black"
          >
            {displayText}
          </Link>
        ))}
      </Box>
    </Box>
  </Box>
)
