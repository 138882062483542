import { Box, Grid } from '@mui/material'
import Divider from '@rentspree/component-2023.components.atoms.divider'
import { useSelector } from 'react-redux'

import { useTracker } from '@hooks'
import env from 'env'
import { selectUserId } from 'store/users'
import { FOOTER } from 'tracker/events'

import { FooterItemSection } from './footer-item-section'
import { FooterSectionRightsReserved } from './footer-rights-reserved'
import { FooterSectionFollowUs } from './footer-section-follow-us'

const RENTSPREE_LOGO = 'https://cdn.rentspree.com/static-files/logo/rentspree-logo-primary.png'

// eslint-disable-next-line
const internalLink = (uri: string): string => `${env.BASE_URL}${uri}`

export const companyInfoLinks = [
  { displayText: 'About us', link: internalLink('/about') },
  { displayText: 'Careers', link: internalLink('/careers') },
  { displayText: 'Contact us', link: internalLink('/contact-us') },
]

export const whatWeServeLinks = [
  { displayText: 'Real estate agents', link: internalLink('/agent') },
  { displayText: 'Landlords', link: internalLink('/landlord-owner') },
  { displayText: 'Property managers', link: internalLink('/property-manager') },
  { displayText: 'Renters', link: internalLink('/renter') },
  { displayText: 'Enterprise', link: internalLink('/exterprise') },
]
type FeatureLink = Record<'displayText' | 'link', string>
export const featureLinks: Array<FeatureLink> = [
  { displayText: 'Tenant screening', link: internalLink('/tenant-screening') },
  { displayText: 'Payments', link: internalLink('/rent-payment') },
  { displayText: 'Rental application', link: internalLink('/rental-application') },
  { displayText: 'Marketing tools', link: internalLink('/listing-pages') },
  { displayText: 'Rent estimate', link: internalLink('/rent-estimate') },
  { displayText: 'Insurance', link: internalLink('/renters-insurance') },
  { displayText: 'Enterprise API integration', link: internalLink('/api-service-integration') },
]

export const bottomLinks = [
  { displayText: 'Privacy policy', link: internalLink('/rentspree-privacy-policy') },
  { displayText: 'Terms of service', link: internalLink('/terms-of-service') },
  { displayText: 'Additional legal terms', link: internalLink('/additional-legal-terms') },
  { displayText: 'Cookie settings', link: internalLink('/#') },
]

const removedFeatureLinks = ['Payments', 'Insurance']

export const loggedInFeatureLinks = featureLinks.filter(
  (element: FeatureLink) => !removedFeatureLinks.includes(element.displayText),
)

export const Footer: React.FC = () => {
  const track = useTracker()
  const withLeavePageViaFooterTracker = (link: IFooterLink): IFooterLink => {
    const modifiedOnClick = (): void => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      track(FOOTER.LEAVE_PAGE_VIA_FOOTER, { click_on: link.displayText })
      if (link.onClick) {
        link.onClick()
      }
    }
    return { ...link, onClick: modifiedOnClick }
  }

  const userId = useSelector(selectUserId)

  return (
    <Box className="agent-profile-footer-container">
      <Divider />
      <Grid container spacing={2} sx={{ justifyContent: 'space-around' }}>
        <Grid item xs={6} sm={3} sx={{ minWidth: '200px' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box>
              <img src={RENTSPREE_LOGO} alt="footer-rentspree-logo" height="24px" width="auto" />
            </Box>
            <FooterItemSection listItem={companyInfoLinks.map(withLeavePageViaFooterTracker)} />
          </Box>
        </Grid>
        <Grid item xs={6} sm={3} sx={{ minWidth: '200px' }}>
          <FooterItemSection
            headerText="Who We Serve"
            listItem={whatWeServeLinks.map(withLeavePageViaFooterTracker)}
          />
        </Grid>
        <Grid item xs={6} sm={3} sx={{ minWidth: '200px' }}>
          <FooterItemSection
            headerText="Products"
            listItem={
              userId
                ? loggedInFeatureLinks.map(withLeavePageViaFooterTracker)
                : featureLinks.map(withLeavePageViaFooterTracker)
            }
          />
        </Grid>
        <Grid item xs={6} sm={3} sx={{ minWidth: '200px' }}>
          <FooterSectionFollowUs />
        </Grid>
      </Grid>
      <FooterSectionRightsReserved listItem={bottomLinks} />
    </Box>
  )
}
