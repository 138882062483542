import type { IReviewItem } from 'store/agent-review-list/types'

import { Box } from '@mui/material'
import Tag from '@rentspree/component-2023.components.atoms.tag'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import Card from '@rentspree/component-2023.components.organisms.card'
import CardElement from '@rentspree/component-2023.components.organisms.card-element'
import Pagination from '@rentspree/component-2023.components.organisms.pagination'
import Rating from '@rentspree/component-2023.components.organisms.rating'
import { useRouter } from 'next/router'

import { useSelector } from 'store'
import {
  selectAgentReviewList,
  selectAgentReviewListPagination,
} from 'store/agent-review-list/selectors'

interface IReviewList {
  status?: 'submitted' | 'published'
}

export const ReviewList: React.FC<IReviewList> = () => {
  const router = useRouter()
  const list = useSelector(selectAgentReviewList)
  const pagination = useSelector(selectAgentReviewListPagination)

  const onPageChange = async (event: React.ChangeEvent<unknown>, current: number) => {
    await router.replace({ query: { ...router.query, agentReviewPage: current } }, undefined, {
      scroll: false,
    })
  }

  if (!pagination.total) {
    return null
  }

  return (
    <Box className="review-list-container" data-testid="review-list-container">
      <Box className="review-list-content">
        {list.map((review: IReviewItem) => {
          const reviewRole = review.reviewer?.role
            ? review.reviewer?.role?.charAt(0).toUpperCase() + review.reviewer?.role?.slice(1)
            : ''
          const reviewerFirstName = review.reviewer?.firstName
          const reviewerLastName =
            review.reviewer?.lastName && review.reviewer?.lastName.length > 0
              ? `${review.reviewer.lastName[0].toUpperCase()}.`
              : ''
          return (
            <Box key={review.id} data-testid="review-item" className="review-item">
              <Card className="review-item-card-container" variant="outlined">
                <CardElement>
                  <Box className="review-item-card">
                    <Box>
                      <Box className="review-item-name">
                        <Typography variant="body-medium">
                          {reviewerFirstName} {reviewerLastName}
                        </Typography>
                        <Typography variant="caption">
                          {review.reviewDate?.toLocaleDateString('en-EN', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric',
                          })}
                        </Typography>
                      </Box>
                      <Box>
                        <Box>
                          <Typography variant="body">{reviewRole}</Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box className="review-item-rating-container">
                      <Box className="review-item-rating">
                        <Rating
                          size="medium"
                          value={review.star}
                          color="black"
                          disabled
                          style={{ opacity: 1, color: '#222222' }}
                        />
                      </Box>
                      <Typography className="review-item-text" variant="body-medium">
                        {review.recommendation}
                      </Typography>
                    </Box>
                    <Box className="review-item-compliments-container">
                      {review.compliments?.map(compliment => (
                        <Tag label={compliment} color="gray" style={{ height: 'unset' }} />
                      ))}
                    </Box>
                  </Box>
                </CardElement>
              </Card>
            </Box>
          )
        })}
      </Box>
      {(pagination?.maxPage || 0) > 1 && (
        <Box className="review-pagination-container">
          <Pagination
            count={pagination.maxPage}
            page={pagination.page}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onChange={onPageChange}
          />
        </Box>
      )}
    </Box>
  )
}
