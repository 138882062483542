import { Grid } from '@mui/material'
import Link from '@rentspree/component-2023.components.atoms.link'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import Card from '@rentspree/component-2023.components.organisms.card'
import CardElement from '@rentspree/component-2023.components.organisms.card-element'
import { generateAddress } from '@rentspree/helper'
import isEmpty from 'lodash/isEmpty'

import { useSelector } from 'store'
import {
  selectAgentContactInfo,
  selectAgentProfileContactInformationWithAdjustUrl,
} from 'store/agent-profile'
import tracker, { events } from 'tracker'

import { SocialContact } from './social-contact'

interface IContactInfo {
  isPreview?: boolean
}

export const ContactInfo: React.FC<IContactInfo> = () => {
  const { contactInformation, phone, email } = useSelector(selectAgentContactInfo)
  const {
    facebookUrl,
    twitterUrl,
    instagramUrl,
    linkedInUrl,
    tiktokUrl,
    primaryWebsiteUrl,
    secondaryWebsiteUrl,
  } = useSelector(selectAgentProfileContactInformationWithAdjustUrl)

  const hasWebSite =
    contactInformation?.primaryWebsiteUrl || contactInformation?.secondaryWebsiteUrl
  const hasProfessionalContactInfo = !!phone || !!email
  const hasBrokerageAddress =
    !isEmpty(contactInformation?.brokerage) &&
    Object.keys(contactInformation?.brokerage ?? {}).some(
      key => !isEmpty(contactInformation?.brokerage?.[key]),
    )

  const hasSocialContact =
    !!contactInformation?.facebookUrl ||
    !!contactInformation?.twitterUrl ||
    !!contactInformation?.instagramUrl ||
    !!contactInformation?.linkedInUrl ||
    !!contactInformation?.tiktokUrl

  return (
    <Card
      data-testid="contact-info-container"
      className="content-card-container"
      variant="outlined"
    >
      <CardElement>
        <Typography variant="title-medium" component="div">
          Contact information
        </Typography>
      </CardElement>
      <CardElement>
        <Grid container>
          {hasBrokerageAddress && (
            <Grid item xs={12} md={6} marginBottom="4px">
              <Typography className="typography-title-small" variant="body-large">
                Brokerage address
              </Typography>
              <Typography marginBottom="4px" variant="body-large" component="div">
                {contactInformation?.brokerage?.name || '-'}
              </Typography>
              <Typography marginBottom="4px" variant="body-large" component="div">
                {generateAddress({
                  ...contactInformation?.brokerage,
                  street: contactInformation?.brokerage?.address,
                })}
              </Typography>
            </Grid>
          )}
          {hasProfessionalContactInfo && (
            <Grid item xs={12} md={6} marginBottom="4px">
              <Typography className="typography-title-small" variant="body-large">
                Professional contact info
              </Typography>
              {phone && (
                <Typography marginBottom="4px" variant="body-large" component="div">
                  {phone}
                </Typography>
              )}
              <Typography marginBottom="4px" variant="body-large" component="div">
                {email || '-'}
              </Typography>
            </Grid>
          )}
          {hasSocialContact && (
            <Grid item xs={12} md={6} marginBottom="4px">
              <SocialContact
                contacts={{
                  facebook: facebookUrl,
                  twitter: twitterUrl,
                  instagram: instagramUrl,
                  linkedin: linkedInUrl,
                  tiktok: tiktokUrl,
                }}
              />
            </Grid>
          )}
          {hasWebSite && (
            <Grid item xs={12} md={6} marginBottom="4px" data-testid="contact-info-website">
              <Typography marginBottom="4px" variant="body" component="div">
                <Link
                  href={primaryWebsiteUrl}
                  target="_blank"
                  rel="noreferrer"
                  className=" text-truncate"
                  onClick={() => {
                    if (!isEmpty(contactInformation?.primaryWebsiteUrl)) {
                      tracker?.trackEvent(events.PUBLIC_PAGE.CLICK_PRIMARY_WEBSITE_LINK)
                      window.open(primaryWebsiteUrl ?? '', '_blank')
                    }
                  }}
                >
                  {contactInformation?.primaryWebsiteUrl}
                </Link>
              </Typography>
              {contactInformation?.secondaryWebsiteUrl && (
                <Typography marginBottom="4px" variant="body" component="div">
                  <Link
                    href={secondaryWebsiteUrl}
                    rel="noreferrer"
                    className="text-truncate"
                    onClick={() => {
                      if (!isEmpty(contactInformation?.secondaryWebsiteUrl)) {
                        tracker?.trackEvent(events.PUBLIC_PAGE.CLICK_SECONDARY_WEBSITE_LINK)
                        window.open(secondaryWebsiteUrl ?? '', '_blank')
                      }
                    }}
                  >
                    {contactInformation?.secondaryWebsiteUrl}
                  </Link>
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      </CardElement>
    </Card>
  )
}
