import type { IEmailModalFormValues } from './types'
import type { ISendEmailToAgentRequestPayload } from 'store/public-messaging/types'

import Drawer from '@rentspree/component-2023.components.organisms.drawer'
import Sheet, { SheetHeader } from '@rentspree/component-2023.components.templates.sheet'
import { buildPath } from '@rentspree/path'
import { useEffect, useState } from 'react'
import { useMedia } from 'react-use'

import env from 'env'
import { useDispatch, useSelector } from 'store'
import { selectAgentProfile } from 'store/agent-profile'
import { actions, selectPublicMessagingApiStatus } from 'store/public-messaging'
import { actions as toastActions } from 'store/toast'
import { selectUserProfile } from 'store/users/selectors'
import tracker, { events } from 'tracker'

import { ContactAgentEmailSuccess } from './contact-agent-email-success'
import { ContactAgentEmailForm } from './form'

interface IProps {
  isShow: boolean
  closeForm: () => void
  agentFullName: string
  defaultMessage: string
  property?: {
    address: string
    id: string
  }
}

export const ContactAgentEmail: React.FC<IProps> = props => {
  const { isShow, closeForm, agentFullName, defaultMessage, property = undefined } = props
  const dispatch = useDispatch()
  const isMobile = useMedia('(max-width: 1016px)')

  const { userId, email: agentEmail } = useSelector(selectAgentProfile)
  const sendEmailApiStatus = useSelector(selectPublicMessagingApiStatus)
  const buttonLocation = 'AP public page'
  const userProfile = useSelector(selectUserProfile) || {}
  const isSignin = Boolean(userProfile.id)

  const eventUserState = isSignin ? 'loggedin' : 'anonymous'
  const formTitle = `Contact ${agentFullName}`

  const [isContactAgentSuccess, setIsContactAgentSuccess] = useState<boolean>(false)

  const contactAgentSuccess = {
    title: 'Message sent!',
    message: isSignin
      ? 'Your inquiry has been sent to the agent or landlord of this property.'
      : 'Create a RentSpree account to streamline the rental process.',
    mainCtaText: isSignin ? undefined : 'Sign up',
    mainCtaOnClick: isSignin
      ? undefined
      : () => {
          tracker?.trackEvent(events.PUBLIC_PAGE.CLICK_SIGN_UP, {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            button_location: buttonLocation,
          })

          const path = buildPath(`${env.BASE_URL}/account/signup`, {}) ?? ''
          window.location.assign(path)
        },
    secondaryCtaText: isSignin ? 'Return to listing' : 'Skip for now',
    secondaryCtaOnClick: () => setIsContactAgentSuccess(false),
  }

  useEffect(() => {
    switch (sendEmailApiStatus) {
      case 'fulfilled':
        closeForm()
        setIsContactAgentSuccess(true)
        break
      case 'rejected':
        closeForm()
        dispatch(
          toastActions.addToast({
            status: 'error',
            titleMessage: 'Something went wrong while sending message. Please try again.',
            bodyMessage: '',
          }),
        )
        break
      default:
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendEmailApiStatus])

  const handleSendEmail = ({ fullName, email, phone, message }: IEmailModalFormValues) => {
    const textMessage = message || defaultMessage

    tracker?.trackEvent(events.PUBLIC_PAGE.CLICK_SEND_MESSAGE, {
      state: eventUserState,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      button_location: buttonLocation,
    })
    const payload: ISendEmailToAgentRequestPayload = {
      sender: {
        fullName,
        email,
        phone: phone || undefined,
      },
      recipient: {
        _id: userId || '',
        email: agentEmail || '',
      },
      message: textMessage,
      property,
    }
    // eslint-disable-next-line no-void
    void dispatch(actions.sendEmailToAgent(payload))
  }

  return (
    <>
      {isMobile && (
        <Sheet
          open={isShow}
          onClose={() => {
            closeForm()
          }}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onOpen={() => {}}
        >
          <SheetHeader header={formTitle} />
          <ContactAgentEmailForm
            handleSubmit={handleSendEmail}
            isLoading={sendEmailApiStatus === 'pending'}
            defaultMessage={defaultMessage}
            profile={userProfile}
          />
        </Sheet>
      )}
      {!isMobile && (
        <Drawer
          open={isShow}
          anchor="right"
          title={formTitle}
          onClose={() => {
            closeForm()
          }}
          displayCloseButton
        >
          <ContactAgentEmailForm
            handleSubmit={handleSendEmail}
            isLoading={sendEmailApiStatus === 'pending'}
            defaultMessage={defaultMessage}
            profile={userProfile}
          />
        </Drawer>
      )}
      <ContactAgentEmailSuccess
        isShow={isContactAgentSuccess}
        isSignin={isSignin}
        title={contactAgentSuccess.title}
        message={contactAgentSuccess.message}
        mainCtaText={contactAgentSuccess.mainCtaText}
        mainCtaOnClick={contactAgentSuccess.mainCtaOnClick}
        secondaryCtaText={contactAgentSuccess.secondaryCtaText}
        secondaryCtaOnClick={contactAgentSuccess.secondaryCtaOnClick}
        closeDialog={() => setIsContactAgentSuccess(false)}
      />
    </>
  )
}
