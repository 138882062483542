import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import SendMessageIcon from '@mui/icons-material/Message'
import { Box } from '@mui/material'
import { Button } from '@rentspree/component-2023.components.atoms.button'
import IconButton from '@rentspree/component-2023.components.atoms.icon-button'
import Tooltip from '@rentspree/component-2023.components.atoms.tooltip'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import { useState } from 'react'

import { PLACEHOLDER as placeHolderData } from 'containers/agent-profile/contact-info/contact-agent/email-modal/constants'
import { ContactAgentEmail } from 'containers/agent-profile/contact-info/contact-agent/email-modal/contact-agent-email'
import { useSelector } from 'store'
import { selectAgentProfileFullName, selectAgentVerifiedStatus } from 'store/agent-profile'
import { interpolateMessage } from 'utils/interpolate-message'

interface IProfessionalProfileHeader {
  coverImageSrc: string
  profileImageSrc: string

  setProfileImageSrc: (src: string) => void
}

export const ProfessionalProfileHeader: React.FC<IProfessionalProfileHeader> = ({
  coverImageSrc,
  profileImageSrc,
  setProfileImageSrc,
}) => {
  const defaultProfileImage = '/profile/default-profile.svg'

  const fullName = useSelector(selectAgentProfileFullName)
  const isAgentVerified = useSelector(selectAgentVerifiedStatus)
  const messageTemplate = placeHolderData.agentProfile.MESSAGE

  const [isShowContactAgent, setIsShowContactAgent] = useState<boolean>(false)
  const handleClickContactAgent = () => {
    setIsShowContactAgent(true)
  }

  return (
    <Box marginBottom="24px">
      <Box>
        <img src={coverImageSrc} alt="agent profile cover" className="cover-image" />
      </Box>
      <Box className="profile-infomation-container">
        <img
          className="profile-image"
          src={profileImageSrc}
          alt="agent profile"
          onError={() => setProfileImageSrc(defaultProfileImage)}
        />
        <Box className="fullname-information">
          <Typography component="span" variant="title-medium">
            {fullName}
          </Typography>
          {isAgentVerified && (
            <Tooltip title="Account verified" placement="top">
              <IconButton>
                <CheckCircleIcon color="success" />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Box className="contact-agent-container">
          <Button
            id="contact-agent-send-message-button"
            variant="contained"
            size="medium"
            color="secondary"
            onClick={handleClickContactAgent}
            startIcon={<SendMessageIcon />}
          >
            Send message
          </Button>
        </Box>
      </Box>
      <ContactAgentEmail
        isShow={isShowContactAgent}
        closeForm={() => setIsShowContactAgent(false)}
        agentFullName={fullName}
        defaultMessage={interpolateMessage(messageTemplate, {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          agent_name: fullName,
        })}
      />
    </Box>
  )
}
