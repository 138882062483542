import type { GetAgentProfileResponse, GetAgentProfileError } from './types'

import { sanitizeUrl } from '@braintree/sanitize-url'
import { createAsyncThunk } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'

import { agentProfileApiInstance } from 'api'

import { NAME } from './constant'

const DEFAULT_PAGE_SIZE = 6

export interface IPayload {
  agentProfileSlug: string
  listingPage?: string | string[] | undefined
}

export const getAgentProfile = createAsyncThunk(
  `${NAME}/GET_AGENT_PROFILE`,
  async (payload: IPayload, { rejectWithValue }) => {
    const { agentProfileSlug, listingPage = '1' } = payload

    try {
      const sanitisedSlug: string = !isEmpty(agentProfileSlug)
        ? sanitizeUrl(agentProfileSlug)
        : agentProfileSlug

      // eslint-disable-next-line no-console
      console.log('getAgentProfile with', sanitisedSlug)
      const queryString = `?page=${
        typeof listingPage === 'string' ? listingPage : listingPage[0]
      }&perPage=${DEFAULT_PAGE_SIZE}`

      const response = await agentProfileApiInstance.get<unknown, GetAgentProfileResponse>(
        `/public/${sanitisedSlug}${sanitisedSlug ? queryString : ''}`,
      )

      return response
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      /* eslint-disable-next-line */
      const error = e?.data as unknown as GetAgentProfileError
      // eslint-disable-next-line no-console
      console.log('getAgentProfile error', error)
      return rejectWithValue(error)
    }
  },
)

export const asyncActions = {
  getAgentProfile,
}
