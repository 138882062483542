import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import YouTubeIcon from '@mui/icons-material/YouTube'
import { Box } from '@mui/material'
import { Link } from '@rentspree/component-2023.components.atoms.link'
import { Typography } from '@rentspree/component-2023.components.atoms.typography'

import TwitterXIcon from './twitter-x-icon'

export const FooterSectionFollowUs: React.FC = () => (
  <Box className="footer-section-follow-us-container">
    <Typography className="footer-section-follow-us-title" variant="body-large">
      Follow Us
    </Typography>
    <Box className="footer-section-follow-us-item-container">
      <Link
        color="black"
        className="footer-section-follow-us-item"
        href="https://www.facebook.com/rentspree"
        data-testid="follow-us-link-facebook"
        target="_blank"
        rel="noreferrer"
      >
        <FacebookIcon /> Facebook
      </Link>
      <Link
        color="black"
        className="footer-section-follow-us-item"
        href="https://www.instagram.com/rentspree/"
        data-testid="follow-us-link-instagram"
        target="_blank"
        rel="noreferrer"
      >
        <InstagramIcon /> Instagram
      </Link>
      <Link
        color="black"
        className="footer-section-follow-us-item"
        href="https://twitter.com/rentspree"
        data-testid="follow-us-link-twitter-x"
        target="_blank"
        rel="noreferrer"
      >
        <TwitterXIcon /> X
      </Link>
      <Link
        color="black"
        className="footer-section-follow-us-item"
        href="https://www.linkedin.com/company/rentspree"
        data-testid="follow-us-link-linkedin"
        target="_blank"
        rel="noreferrer"
      >
        <LinkedInIcon /> LinkedIn
      </Link>
      <Link
        color="black"
        className="footer-section-follow-us-item"
        href="https://www.youtube.com/c/rentspree"
        data-testid="follow-us-link-youtube"
        target="_blank"
        rel="noreferrer"
      >
        <YouTubeIcon /> YouTube
      </Link>
    </Box>
  </Box>
)
