import { Box } from '@mui/material'
import Typography from '@rentspree/component-2023.components.atoms.typography'

import { useSelector } from 'store'
import { useHashRouter } from 'store/@hooks/use-hash-router'
import { selectAgentReviewSummaryTotalReviews } from 'store/agent-review-summary'

import { ReviewList } from './review-list'
import { SummaryReview } from './review-summary'

interface IReview {
  offsetScroll?: number
  isDisplayFooter?: boolean
}

export const Review: React.FC<IReview> = ({ offsetScroll = 100 }) => {
  const agentReviewTotalReviews = useSelector(selectAgentReviewSummaryTotalReviews)
  const { scrollTargetElementRef } = useHashRouter<HTMLDivElement>({
    id: 'review',
    offset: offsetScroll - 100,
  })

  return (
    <Box
      id="review"
      ref={scrollTargetElementRef}
      marginBottom="80px"
      data-testid="review-contianer"
    >
      <Typography variant="title-medium" fontWeight="bold" component="div" marginBottom="24px">
        Reviews ({agentReviewTotalReviews})
      </Typography>
      {agentReviewTotalReviews !== 0 ? (
        <Box>
          <SummaryReview />
          <ReviewList />
        </Box>
      ) : (
        <Typography variant="h6" marginY="80px" marginX="16">
          There is no review yet.
        </Typography>
      )}
    </Box>
  )
}
