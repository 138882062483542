/* eslint-disable @typescript-eslint/no-empty-function */
import { Button } from '@rentspree/component-2023.components.atoms.button'
import { useState, useRef, useEffect } from 'react'

import { ShowMoreTextWrapper, TextOverlay, TextWrapper } from './style'

const LINE_HEIGHT_UNIT = 'px'

interface IShowMoreText extends IElement {
  line?: number
  mLine?: number
  size?: string
  lineHeight?: number
  mLineHeight?: number
  lineOffset?: number
  mLineOffset?: number
  onToggleDescription?: (hideText: boolean) => void
  onReady?: (wrapperOffsetHeight: number) => void
}

type CalculateCssHeightProps = {
  line: number
  lineHeight: number
  unit: string
  defaultValue?: string
}

export function calculateCssHeight({
  line,
  lineHeight,
  unit,
  defaultValue = 'auto',
}: CalculateCssHeightProps): string {
  return line > 0 ? `${line * lineHeight}${unit}` : defaultValue
}

function useIsMobileSize() {
  const [size, setSize] = useState(0)
  useEffect(() => {
    function updateSize() {
      setSize(window.innerWidth)
    }
    window.addEventListener('resize', updateSize)
    updateSize()
    return () => window.removeEventListener('resize', updateSize)
  }, [])
  return size < 991
}
export const ShowMoreText: React.FC<React.PropsWithChildren<IShowMoreText>> = ({
  line = 0,
  mLine = 0,
  size,
  lineHeight = 28,
  mLineHeight = 0,
  lineOffset = 0,
  mLineOffset = 0,
  onToggleDescription = () => {},
  onReady = () => {},
  children,
  ...props
}) => {
  const wrapper = useRef<HTMLDivElement>(null)
  const [wrapperHeight, setWrapperHeight] = useState(0)
  const [isAlwaysShow, setIsAlwaysShow] = useState(true)
  const [isHideText, setIsHideText] = useState(false)
  const [isInitial, setIsInitial] = useState(true)
  const isMobile = useIsMobileSize()
  const hasMobileSetting = mLineOffset && mLine && mLineHeight
  const selectedLine = isMobile && hasMobileSetting ? mLine : line
  const selectedLineHeight = isMobile && mLineHeight ? mLineHeight : lineHeight
  const selectedLineOffset = isMobile && hasMobileSetting ? mLineOffset : lineOffset
  const buttonText = isHideText ? 'Show more' : 'Show less'
  const height = calculateCssHeight({
    line: selectedLine,
    lineHeight: selectedLineHeight,
    unit: LINE_HEIGHT_UNIT,
  })
  const offsetHeight = calculateCssHeight({
    line: selectedLineOffset ?? 0,
    lineHeight: selectedLineHeight,
    unit: LINE_HEIGHT_UNIT,
    defaultValue: '0px',
  })

  useEffect(() => {
    if (wrapper.current) {
      setWrapperHeight(wrapper.current.offsetHeight)
      onReady(
        Number(isInitial) * (selectedLine * selectedLineHeight - wrapper.current.offsetHeight),
      )
      if (isInitial) setIsInitial(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitial])

  useEffect(() => {
    if (selectedLine > 0) {
      const renderedLineCount = Math.round(wrapperHeight / selectedLineHeight)
      if (renderedLineCount > selectedLine) {
        setIsAlwaysShow(false)
        setIsHideText(true)
      }
    }
  }, [selectedLine, selectedLineHeight, wrapperHeight])

  const onHideTextClick = () => {
    setIsHideText(!isHideText)
    onToggleDescription(!isHideText)
  }

  return (
    <ShowMoreTextWrapper {...props}>
      <TextWrapper
        ref={wrapper}
        $hideText={isHideText}
        $size={size}
        $lineHeight={`${lineHeight}${LINE_HEIGHT_UNIT}`}
        $height={height}
      >
        {children}
        {isHideText && <TextOverlay data-testid="textOverlay" $lineOffset={offsetHeight} />}
      </TextWrapper>
      {!isAlwaysShow && (
        <Button
          variant="text"
          className="underline"
          color="secondary"
          onClick={onHideTextClick}
          sx={{ marginLeft: '-16px' }}
        >
          {buttonText}
        </Button>
      )}
    </ShowMoreTextWrapper>
  )
}
