import type { IEmailModalFormValues } from './types'
import type { IUserProfile } from 'store/users/types'

import { Form, Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'

import {
  BlueGradientButton,
  Checkbox,
  NumberFormatInputWithLabel,
  TextAreaWithLabel,
  TextInputWithLabel,
} from 'components'

import { PLACEHOLDER as placeHolderData } from './constants'
import { EmailModalFooter } from './footer'
import { TermBox } from './term-box'
import { contactAgentEmailModalFormValidation } from './validations'

interface IContactAgentEmailFormOldProps {
  handleSubmit: (values: IEmailModalFormValues) => void
  isLoading?: boolean
  defaultMessage: string
  profile?: IUserProfile
  prevFormData?: IEmailModalFormValues
}

export const ContactAgentEmailFormOld: React.FC<IContactAgentEmailFormOldProps> = props => {
  const {
    handleSubmit,
    isLoading = false,
    defaultMessage,
    profile = {},
    prevFormData = undefined,
  } = props
  const initialValues: IEmailModalFormValues = prevFormData || {
    fullName: profile?.fullName || '',
    email: profile?.email || '',
    phone: profile?.phone || undefined,
    message: '',
    isAccept: false,
  }
  const isLoggedIn = !isEmpty(profile)
  const PLACEHOLDER = placeHolderData.agentProfile

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={contactAgentEmailModalFormValidation}
      onSubmit={handleSubmit}
    >
      {({ handleChange, handleBlur, setFieldValue, values, errors, touched }) => (
        <Form>
          <TextInputWithLabel
            $display={profile?.fullName ? 'none' : 'block'}
            id="emailSenderFullName"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.fullName}
            error={touched?.fullName && errors?.fullName}
            name="fullName"
            label="First and Last Name"
            placeholder={PLACEHOLDER.FULL_NAME}
            disabled={isLoading}
            isRequired
            $mb="8"
            $mMargin="0 0 16px"
          />
          <TextInputWithLabel
            $display={profile?.email ? 'none' : 'block'}
            id="emailSenderEmail"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            error={touched?.email && errors?.email}
            name="email"
            label="Email"
            placeholder={PLACEHOLDER.EMAIL}
            disabled={isLoading}
            isRequired
            $mb="8"
            $mMargin="0 0 16px"
          />
          <NumberFormatInputWithLabel
            $display={isLoggedIn ? 'none' : 'block'}
            id="emailSenderPhone"
            type="phone"
            onChange={target => {
              setFieldValue('phone', target.value)
            }}
            onBlur={handleBlur}
            value={values.phone}
            error={touched?.phone && errors?.phone}
            name="phone"
            label="Phone"
            placeholder={PLACEHOLDER.PHONE}
            disabled={isLoading}
            $mb="8"
            $mMargin="0 0 16px"
          />
          <TextAreaWithLabel
            id="emailMessage"
            type="text"
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={(event: React.FocusEvent<HTMLTextAreaElement>) => {
              if (event.target.value === '') {
                setFieldValue('message', defaultMessage)
              }
            }}
            value={values.message}
            name="message"
            label="Message"
            placeholder={defaultMessage}
            disabled={isLoading}
          />
          {values.phone && !isLoggedIn && (
            <Checkbox
              id="checkbox"
              onCheck={() => {
                const isAcceptValue = !values.isAccept
                setFieldValue('isAccept', isAcceptValue)
              }}
              name="isAccept"
              text={<TermBox />}
              isChecked={values.isAccept}
              isDisabled={isLoading}
              $margin="16px 0 0"
            />
          )}
          <BlueGradientButton
            id="sendEmailButton"
            type="submit"
            width="100%"
            btnSize="lg"
            mBtnSize="md"
            margin="16px 0"
            mMargin="24px 0"
            loading={isLoading}
            disabled={!(isLoggedIn || !values?.phone ? true : values.isAccept)}
          >
            Send
          </BlueGradientButton>
          <EmailModalFooter hasTerm={!isEmpty(values.phone)} />
        </Form>
      )}
    </Formik>
  )
}
