import type { IEmailModalFormValues } from './types'
import type { IUserProfile } from 'store/users/types'

import { Box, Grid } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import Button from '@rentspree/component-2023.components.atoms.button'
import Checkbox from '@rentspree/component-2023.components.atoms.checkbox'
import TextInput from '@rentspree/component-2023.components.atoms.text-input'
import StickyFooterTemplate from '@rentspree/component-2023.components.templates.sticky-footer-template'
import { Form, Formik } from 'formik'
import isEmpty from 'lodash/isEmpty'

import { PLACEHOLDER as placeHolderData } from './constants'
import { EmailAcceptTerm } from './email-accept-term'
import { PhoneAcceptTerm } from './phone-accept-term'
import { contactAgentEmailModalFormValidation } from './validations'

interface IContactAgentEmailFormProps {
  handleSubmit: (values: IEmailModalFormValues) => void
  isLoading?: boolean
  defaultMessage: string
  profile?: IUserProfile
  prevFormData?: IEmailModalFormValues
}

export const ContactAgentEmailForm: React.FC<IContactAgentEmailFormProps> = props => {
  const {
    handleSubmit,
    isLoading = false,
    defaultMessage,
    profile = {},
    prevFormData = undefined,
  } = props
  const initialValues: IEmailModalFormValues = prevFormData || {
    fullName: profile?.fullName || '',
    email: profile?.email || '',
    phone: profile?.phone || undefined,
    message: defaultMessage,
    isAccept: false,
  }
  const isLoggedIn = !isEmpty(profile)
  const PLACEHOLDER = placeHolderData.agentProfile

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={contactAgentEmailModalFormValidation}
      onSubmit={handleSubmit}
    >
      {({ handleChange, handleBlur, setFieldValue, setFieldTouched, values, errors, touched }) => (
        <Form>
          <Box className="contact-agent-email-form-container">
            <Grid className="contact-agent-email-form-body" container spacing={2}>
              <Grid item xs={12}>
                <TextInput
                  id="emailSenderFullName"
                  required
                  inputProps={{
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'data-testid': 'contact-agent-email-form-fullname',
                  }}
                  name="fullName"
                  label="First and last Name"
                  placeholder={PLACEHOLDER.FULL_NAME}
                  error={!!touched?.fullName && !!errors?.fullName}
                  helperText={touched?.fullName ? errors?.fullName ?? '' : ''}
                  value={values.fullName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="emailSenderEmail"
                  required
                  inputProps={{
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'data-testid': 'contact-agent-email-form-email',
                  }}
                  name="email"
                  label="Email"
                  placeholder={PLACEHOLDER.EMAIL}
                  error={!!touched?.email && !!errors?.email}
                  helperText={touched?.email ? errors.email ?? '' : ''}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="emailSenderPhone"
                  inputProps={{
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'data-testid': 'contact-agent-email-form-phone',
                  }}
                  name="phone"
                  label="Phone"
                  placeholder={PLACEHOLDER.PHONE}
                  patternFormatOptions={{
                    format: '(###)###-####',
                  }}
                  error={!!touched?.phone && !!errors?.phone}
                  helperText={errors.phone ?? ''}
                  value={values.phone}
                  onChange={event => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    setFieldValue('phone', event.target.value)
                  }}
                  onBlur={handleBlur}
                  disabled={isLoading}
                  showOptional
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  id="emailMessage"
                  required
                  inputProps={{
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'data-testid': 'contact-agent-email-form-message',
                  }}
                  name="message"
                  label="Message"
                  placeholder={defaultMessage}
                  error={!!touched?.message && !!errors?.message}
                  helperText={errors.message ?? ''}
                  value={values.message}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onFocus={(event: React.FocusEvent<HTMLTextAreaElement>) => {
                    if (event.target.value === '') {
                      setFieldTouched('message', true, true)
                      setFieldValue('message', defaultMessage)
                    }
                  }}
                  InputLabelProps={{
                    shrink: Boolean(values.message) || touched.message,
                  }}
                  disabled={isLoading}
                  multiline
                  rows={6}
                />
              </Grid>
              {values.phone && !isLoggedIn && (
                <Grid item xs={12}>
                  <FormControlLabel
                    data-testid="form-contact-agent-accept-phone"
                    id="checkbox"
                    name="isAccept"
                    className="contact-agent-email-form-phone-accept"
                    control={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <Checkbox
                        checked={values.isAccept}
                        onChange={() => {
                          const isAcceptValue = !values.isAccept
                          setFieldValue('isAccept', isAcceptValue)
                        }}
                      />
                    }
                    label={<PhoneAcceptTerm />}
                    disabled={isLoading}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <EmailAcceptTerm hasTerm={!isEmpty(values.phone)} />
              </Grid>
            </Grid>
            <Box className="contact-agent-email-form-footer-container">
              <StickyFooterTemplate>
                <Box className="contact-agent-email-form-footer-cta-wrapper">
                  <Button
                    id="sendEmailButton"
                    className="contact-agent-email-form-footer-cta-submit-button"
                    data-testid="contact-agent-email-form-submit-button"
                    type="submit"
                    variant="contained"
                    size="medium"
                    color="secondary"
                    disabled={isLoading || !(isLoggedIn || !values?.phone ? true : values.isAccept)}
                  >
                    Send message
                  </Button>
                </Box>
              </StickyFooterTemplate>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}
