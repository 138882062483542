import Link from '@rentspree/component-2023.components.atoms.link'
import Typography from '@rentspree/component-2023.components.atoms.typography'

import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from 'constant'

import { EMAIL_AGREEMENT_TERMS_AND_PRIVACY, EMAIL_AGREEMENT } from './constants'

type EmailAcceptTermProps = {
  hasTerm: boolean
}

export const EmailAcceptTerm: React.FC<EmailAcceptTermProps> = ({ hasTerm }) => (
  <Typography variant="body">
    {hasTerm ? (
      EMAIL_AGREEMENT
    ) : (
      <>
        {EMAIL_AGREEMENT_TERMS_AND_PRIVACY}
        <Link
          color="black"
          variant="bodySmall"
          href={TERMS_OF_USE_URL}
          target="_blank"
          underline="none"
        >
          Terms of use
        </Link>{' '}
        and{' '}
        <Link
          color="black"
          variant="bodySmall"
          href={PRIVACY_POLICY_URL}
          target="_blank"
          underline="none"
        >
          Privacy policy
        </Link>
        .
      </>
    )}
  </Typography>
)
