import Link from '@rentspree/component-2023.components.atoms.link'
import Typography from '@rentspree/component-2023.components.atoms.typography'

import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from 'constant'

import { TERMS_AND_PRIVACY } from './constants'

export const PhoneAcceptTerm: React.FC = () => (
  <Typography variant="body">
    {TERMS_AND_PRIVACY}
    <Link
      color="black"
      variant="bodySmall"
      href={TERMS_OF_USE_URL}
      target="_blank"
      underline="none"
    >
      Terms of use
    </Link>{' '}
    and{' '}
    <Link
      color="black"
      variant="bodySmall"
      href={PRIVACY_POLICY_URL}
      target="_blank"
      underline="none"
    >
      Privacy policy
    </Link>
    .
  </Typography>
)
