import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Box, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import Button from '@rentspree/component-2023.components.atoms.button'
import Dialog from '@rentspree/component-2023.components.atoms.dialog'
import Typography from '@rentspree/component-2023.components.atoms.typography'
import Sheet from '@rentspree/component-2023.components.templates.sheet'
import { useMedia } from 'react-use'

interface IContactAgentEmailSuccess {
  isShow: boolean
  isSignin: boolean
  title: string
  message: string
  mainCtaText?: string
  mainCtaOnClick?: () => void
  secondaryCtaText?: string
  secondaryCtaOnClick?: () => void
  closeDialog: () => void
}

export const ContactAgentEmailSuccess: React.FC<IContactAgentEmailSuccess> = props => {
  const {
    isShow,
    title,
    message,
    mainCtaText,
    mainCtaOnClick,
    secondaryCtaText,
    secondaryCtaOnClick,
    closeDialog,
  } = props
  const isMobile = useMedia('(max-width: 1016px)')

  const CTA = (
    <Box className="contact-agent-email-success-cta-container">
      {mainCtaText && (
        <Button variant="contained" color="secondary" onClick={mainCtaOnClick}>
          {mainCtaText}
        </Button>
      )}
      {secondaryCtaText && (
        <Button
          className="underline"
          variant="text"
          color="secondary"
          onClick={secondaryCtaOnClick}
        >
          {secondaryCtaText}
        </Button>
      )}
    </Box>
  )

  if (isMobile) {
    return (
      <Sheet
        className="contact-agent-email-success contact-agent-email-success-sheet"
        open={isShow}
        onClose={closeDialog}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onOpen={() => {}}
        actionSection={CTA}
        hideActionDivider
      >
        <Box className="contact-agent-email-success-title-container">
          <CheckCircleIcon className="contact-agent-email-success-title-icon" color="success" />
          <Typography className="contact-agent-email-success-title-text" variant="title-large">
            {title}
          </Typography>
        </Box>
        <Box className="contact-agent-email-success-message-container">
          <Typography variant="body-medium">{message}</Typography>
        </Box>
      </Sheet>
    )
  }

  return (
    <Dialog
      className="contact-agent-email-success contact-agent-email-success-dialog"
      open={isShow}
      onClose={closeDialog}
    >
      <Box className="contact-agent-email-success-title-container">
        <CheckCircleIcon className="contact-agent-email-success-title-icon" color="success" />
        <Typography className="contact-agent-email-success-title-text" variant="title-large">
          {title}
        </Typography>
      </Box>
      <DialogContent className="contact-agent-email-success-message-container">
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>{CTA}</DialogActions>
    </Dialog>
  )
}
