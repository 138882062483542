import type { PlaceHolderProps } from './types'
import type { UserType } from 'store/users/types'

import { buildPath } from '@rentspree/path'

import {
  makeShowSweetAlert,
  showSweetAlert,
  RentSpeeSweetAlertOption,
} from 'components/common/organisms/sweetalert'
import env from 'env'
import tracker, { events } from 'tracker'

export const TERMS_AND_PRIVACY =
  'I agree to receive calls or text messages (including autodialed marketing messages and prerecorded or artificial voice calls), ' +
  'from or on behalf of RentSpree, its real estate agents, property managers, and landlords (“Partners”). Consent is not a condition of using the services. ' +
  // eslint-disable-next-line quotes
  "Msg & data rates apply. RentSpree may share your contact information with its Partners to assist you in your property search. RentSpree's "

export const EMAIL_AGREEMENT =
  'I agree to receive emails from or on behalf of RentSpree and its Partners related to properties that I may be interested in. '

export const EMAIL_AGREEMENT_TERMS_AND_PRIVACY =
  'I agree to receive emails from or on behalf of RentSpree, its real estate agents, property managers, and landlords related to properties that I may be interested in. '

export const PLACEHOLDER: PlaceHolderProps = {
  agentProfile: {
    FULL_NAME: 'First and last Name',
    EMAIL: 'hello@email.com',
    PHONE: '(000) 000-0000',
    MESSAGE: `Hi {agent_name}, 
I’m looking for a rental home. Could you please contact me?`,
  },
}

const goToMessagesModal = makeShowSweetAlert({
  ...RentSpeeSweetAlertOption,
  customClass: {
    cancelButton: 'rsp-swal-btn rsp-swal-cancel-btn',
    confirmButton: 'rsp-swal-btn rsp-swal-confirm-btn go-to-messages-btn',
    denyButton: 'rsp-swal-btn rsp-swal-deny-btn',
    container: 'rsp-swal-container go-to-messages-container',
  },
})

export const continueToMessage = (buttonLocation: string, userType: UserType): void => {
  if (window !== undefined) {
    let url = ''
    switch (userType) {
      case 'landlord':
        url = `${env.BASE_URL}/dashboard/v2/messaging`
        break
      case 'renter':
        url = `${env.BASE_URL}/dashboard/v2/public/messaging`
        break
      default:
        break
    }
    tracker?.trackEvent(events.PUBLIC_PAGE.CLICK_GO_TO_MESSAGE, {
      state: 'loggedin',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      button_location: buttonLocation,
    })
    window.location.assign(url)
  }
}

export const openSweetAlertForLoggedIn = (buttonLocation: string, userType: UserType): void => {
  // eslint-disable-next-line no-void
  void goToMessagesModal(
    {
      title: 'Message sent!',
      icon: 'success',
      showCancelButton: true,
      showCloseButton: true,
      html: 'You can chat with an agent via messages for more information.',
      confirmButtonText: 'Go to Messages',
      cancelButtonText: 'Return to Listing',
    },
    () => continueToMessage(buttonLocation, userType),
  )
}

export const continueToSignUp = (buttonLocation: string, emailApplicant: string): void => {
  tracker?.trackEvent(events.PUBLIC_PAGE.CLICK_SIGN_UP, {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    button_location: buttonLocation,
  })

  // TO-DO: fix 'buildPath' in @rentspree/path to return only string
  const path =
    buildPath(
      `${env.BASE_URL}/account/signup`,
      {},
      {
        continue: '/dashboard/v2/public/messaging',
        preSelect: 'applicant',
        email: emailApplicant,
      },
    ) ?? ''
  window.location.assign(path)
}

export const openSweetAlertForAnonymousUser = (
  buttonLocation: string,
  emailApplicant: string,
): void => {
  // eslint-disable-next-line no-void
  void showSweetAlert(
    {
      title: 'Message sent!',
      icon: 'success',
      html: 'Create a RentSpree account to streamline the rental process and never miss a message again. ',
      showCloseButton: true,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sign Up',
      cancelButtonText: 'Skip for Now',
    },
    () => continueToSignUp(buttonLocation, emailApplicant),
  )
}

export const openSweetAlertForAnonymousUserPrescreen = (
  buttonLocation: string,
  emailApplicant: string,
): void => {
  // eslint-disable-next-line no-void
  void showSweetAlert(
    {
      title: 'Message sent!',
      icon: 'success',
      // eslint-disable-next-line quotes
      html: "<p>You'll receive a response to your message in your email inbox.<br /><br />Create a RentSpree account to streamline the rental process and never miss a message again.</p>",
      showCloseButton: true,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sign Up',
      cancelButtonText: 'Return to Listing',
    },
    () => continueToSignUp(buttonLocation, emailApplicant),
  )
}

export const MESSAGE_TYPE = {
  PRE_SCREEN: 'pre-screen',
  MESSAGE: 'msg',
}
