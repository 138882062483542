import type { RefObject } from 'react'

import { Box } from '@mui/material'
import { useFeatureGate } from '@statsig/react-bindings'
import Head from 'next/head'
import { useState, useEffect, useRef, useCallback } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { useSendPublicProfilePageViewEvent } from '@hooks'
import { Div, Hr } from 'components'
import {
  NavbarV2,
  Footer,
  FooterOld,
  AboutMe,
  AboutMeOld,
  Experience,
  ExperienceOld,
  Specialty,
  SpecialtyOld,
  ContactInfo,
  ContactInfoOld,
  HeadWithMetaTags,
  Toast,
  ActiveListing,
  ActiveListingOld,
} from 'containers'
import { Review, ReviewOld } from 'containers/agent-profile/review'
import {
  Container,
  Content,
  Panel,
  CoverImage,
  ProfileImage,
  ProfileImageWrapper,
  FontWrapper,
} from 'containers/agent-profile/styled'
import { ProfessionalProfileHeader } from 'containers/professional-profile-header'
import env from 'env'
import { useSelector, getStoreServerSideProps } from 'store'
import { usePreviewPostMessage } from 'store/@hooks/use-preview-post-message'
import {
  actions as agentProfileActions,
  selectAgentProfile,
  selectAgentProfileImage,
  selectSharingMetaProperties,
  selectAgentCoverImage,
  selectAgentProfileHeaderTitle,
  selectAgentProfileFullName,
} from 'store/agent-profile'
import { actions as agentReviewListActions } from 'store/agent-review-list'
import { actions as agentReviewSummaryActions } from 'store/agent-review-summary'
import { actions as analyticDataActions } from 'store/analytic-data'
import { findOrCreateCookie } from 'utils/cookie'
import { NEW_UI_AGENT_PUBLIC_PROFILE } from 'utils/feature-flag/constants'
import { getStatsigInitializeValues, type IGetFeatureFlag } from 'utils/feature-flag/instance'
import { useDispatchFeatureFlagValueByKeys } from 'utils/feature-flag/utils'

type AgentProfilePageProps = {
  isPreview?: boolean
}

const defaultProfileImage = '/profile/default-profile.svg'

const AgentProfilePage: React.FC<AgentProfilePageProps> = ({ isPreview = false }) => {
  const [profileImageSrc, setProfileImageSrc] = useState(defaultProfileImage)
  const [offsetHeightAboutMeShowMore, setOffsetHeightAboutMeShowMore] = useState(0)
  const [offsetHeightExperienceShowMore, setOffsetHeightExperienceShowMore] = useState(0)
  const [offsetHeightSpecialtyShowMore, setOffsetHeightSpecialtyShowMore] = useState(0)

  const image = useSelector(selectAgentProfileImage)
  const coverImage = useSelector(selectAgentCoverImage)
  const headerTitle = useSelector(selectAgentProfileHeaderTitle)
  const fullName = useSelector(selectAgentProfileFullName)
  const metaContents = useSelector(selectSharingMetaProperties)

  const previewAgentProfileRef = useRef<HTMLDivElement>(null)
  useSendPublicProfilePageViewEvent(isPreview)

  const { value: isNewAgentPublicProfile } = useFeatureGate(NEW_UI_AGENT_PUBLIC_PROFILE)

  useEffect(() => {
    setProfileImageSrc(image)
  }, [image])

  const calculateHeight = (currentRef: RefObject<HTMLDivElement>): number => {
    const { current } = currentRef
    if (current) {
      return current.offsetTop + current.clientHeight
    }
    return 0
  }

  usePreviewPostMessage(previewAgentProfileRef, calculateHeight)

  const onShowMoreAboutMeReady = useCallback((v: number) => {
    setOffsetHeightAboutMeShowMore(v)
  }, [])

  const onShowMoreExperienceReady = useCallback((v: number) => {
    setOffsetHeightExperienceShowMore(v)
  }, [])

  const onShowMoreSpecialtyReady = useCallback((v: number) => {
    setOffsetHeightSpecialtyShowMore(v)
  }, [])

  if (isNewAgentPublicProfile) {
    return (
      <>
        <HeadWithMetaTags metaContents={metaContents} title={headerTitle} />
        <Head>
          <link
            href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap"
            rel="stylesheet"
          />
        </Head>
        {!isPreview && <NavbarV2 />}
        <Toast />
        <Box>
          <ProfessionalProfileHeader
            coverImageSrc={coverImage}
            profileImageSrc={profileImageSrc}
            setProfileImageSrc={setProfileImageSrc}
          />

          <Box className="content-container">
            <AboutMe onShowMoreTextReady={onShowMoreAboutMeReady} />
            <ContactInfo isPreview={isPreview} />
            <Experience onShowMoreTextReady={onShowMoreExperienceReady} />
            <Specialty onShowMoreTextReady={onShowMoreSpecialtyReady} />
            <ActiveListing />
            <Review
              isDisplayFooter={!isPreview}
              offsetScroll={
                offsetHeightAboutMeShowMore +
                offsetHeightExperienceShowMore +
                offsetHeightSpecialtyShowMore
              }
            />
            <Footer />
          </Box>
        </Box>
      </>
    )
  }

  return (
    <>
      <HeadWithMetaTags metaContents={metaContents} title={headerTitle} />
      <Head>
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap"
          rel="stylesheet"
        />
      </Head>
      {!isPreview && <NavbarV2 />}
      <Toast />
      <FontWrapper>
        <Div className="w-100 d-flex flex-column align-items-center" ref={previewAgentProfileRef}>
          <Container>
            <>
              <CoverImage src={coverImage} alt="agent profile cover image" />
              <ProfileImageWrapper>
                <ProfileImage
                  src={profileImageSrc}
                  alt={`${fullName}'s profile image`}
                  onError={() => setProfileImageSrc(defaultProfileImage)}
                />
              </ProfileImageWrapper>
            </>
            <Content>
              <Panel width="616px">
                <AboutMeOld onShowMoreTextReady={onShowMoreAboutMeReady} />
                <ExperienceOld onShowMoreTextReady={onShowMoreExperienceReady} />
                <SpecialtyOld onShowMoreTextReady={onShowMoreSpecialtyReady} />
              </Panel>
              <Panel width="280px" margin="0 0 0 50px">
                <Hr $hideDesktop $mMargin="20px 0" />
                <ContactInfoOld isPreview={isPreview} />
              </Panel>
              <Panel>
                <Div $hideDesktop>
                  <Hr />
                  <ActiveListingOld isDisplayFooter={!isPreview} />
                </Div>
              </Panel>
              <Panel>
                <Div $hideDesktop>
                  <Hr />
                  <ReviewOld
                    isDisplayFooter={!isPreview}
                    offsetScroll={
                      offsetHeightAboutMeShowMore +
                      offsetHeightExperienceShowMore +
                      offsetHeightSpecialtyShowMore
                    }
                  />
                </Div>
              </Panel>
            </Content>
            <Div $width="960px" $mt={30} $ml={40} $hideMobile>
              <Hr $width="975px" />
              <ActiveListingOld isDisplayFooter={!isPreview} />
            </Div>
            <Div $width="960px" $mt={30} $ml={40} $hideMobile>
              <Hr $width="975px" />
              <ReviewOld
                isDisplayFooter={!isPreview}
                offsetScroll={
                  offsetHeightAboutMeShowMore +
                  offsetHeightExperienceShowMore +
                  offsetHeightSpecialtyShowMore
                }
              />
            </Div>
          </Container>
        </Div>
      </FontWrapper>
      {!isPreview && <FooterOld />}
    </>
  )
}

export const getServerSidePropsCB: Parameters<typeof getStoreServerSideProps>[0] =
  store => async context => {
    const {
      params: { slug } = {},
      query: { preview, listingPage, agentReviewPage } = {},
      req,
      res,
    } = context
    const agentProfileSlug: string = (slug || '') as string
    const uid = (
      findOrCreateCookie('_rsp_uid', uuidv4(), {
        req,
        res,
        expires: new Date(Date.UTC(2038, 1, 19, 4, 14, 6, 0)),
        httpOnly: true,
        secure: true,
        domain: env.DOMAIN_NAME,
        sameSite: 'lax',
      }) ?? ''
    ).toString()
    store.dispatch(analyticDataActions.setAnalyticData({ uid }))

    try {
      const response = await store.dispatch(
        agentProfileActions.getAgentProfile({ agentProfileSlug, listingPage }),
      )
      if (response.meta.requestStatus === 'rejected') {
        return { notFound: true }
      }
      const agentProfile = selectAgentProfile(store.getState())
      const { slug: profileSlug, customSlug } = agentProfile
      if (customSlug && profileSlug === agentProfileSlug) {
        return {
          redirect: {
            destination: `/profile/${customSlug}`,
            permanent: false,
          },
        }
      }

      const isPreview = !!preview

      const initializeValues = await getStatsigInitializeValues()

      // TODO: add more feature flag key here
      const featureFlagKeys: IGetFeatureFlag[] = []

      // TODO: need to implement get Statsig flag in this fn when add a new feature flag
      await useDispatchFeatureFlagValueByKeys(store, featureFlagKeys)
      await store.dispatch(
        agentReviewListActions.getAgentReviewList({
          agentProfileSlug,
          page: agentReviewPage,
        }),
      )
      await store.dispatch(agentReviewSummaryActions.getAgentReviewSummary({ agentProfileSlug }))

      return { props: { isPreview, initializeValues } }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e)
      return { notFound: true }
    }
  }

export const getServerSideProps =
  getStoreServerSideProps<Record<string, unknown>>(getServerSidePropsCB)

export default AgentProfilePage
