/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box } from '@mui/material'
import { Typography } from '@rentspree/component-2023.components.atoms.typography'
import Card from '@rentspree/component-2023.components.organisms.card'
import CardElement from '@rentspree/component-2023.components.organisms.card-element'

import { ShowMoreText } from 'components/common'
import { useSelector } from 'store'
import { selectAgentProfileAboutMe } from 'store/agent-profile'

interface IAboutMe {
  onShowMoreTextReady?: (v: number) => void
}

export const AboutMe: React.FC<IAboutMe> = ({ onShowMoreTextReady }) => {
  const agentProfileAboutMe = useSelector(selectAgentProfileAboutMe)

  return (
    <Card className="content-card-container" variant="outlined">
      <CardElement>
        {agentProfileAboutMe.headline && (
          <Box>
            <Typography variant="title-medium">{agentProfileAboutMe.headline}</Typography>
          </Box>
        )}
        {agentProfileAboutMe.aboutMe && (
          <ShowMoreText
            $margin="24px 0 0 0"
            $mMargin="16px 0 0 0"
            line={8}
            lineOffset={6}
            onReady={onShowMoreTextReady}
          >
            <Typography variant="body-large" component="div" sx={{ textAlign: 'justify' }}>
              {agentProfileAboutMe.aboutMe}
            </Typography>
          </ShowMoreText>
        )}
      </CardElement>
    </Card>
  )
}
