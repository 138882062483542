import { Grid, Typography } from '@mui/material'
import Card from '@rentspree/component-2023.components.organisms.card'
import CardElement from '@rentspree/component-2023.components.organisms.card-element'
import { isEmpty, pickBy } from 'lodash'

import { ShowMoreText } from 'components'
import { useSelector } from 'store'
import { selectAgentSpecialty } from 'store/agent-profile'

export interface IAgentSpecialty {
  specialties?: {
    renterAgent: boolean
    listingAgent: boolean
    buyerAgent: boolean
    foreclosure: boolean
    shortSale: boolean
    consulting: boolean
    relocation: boolean
    commercialRealEstate: boolean
    other: string
  }
  serviceAreas?: [string]
  languages?: [string]
}

const TITLE = 'Expertise'

const SPECIALTIES_LABEL: { [k in keyof IAgentSpecialty['specialties']]: string } = {
  renterAgent: 'Rental Agent',
  listingAgent: 'Listing Agent',
  // eslint-disable-next-line quotes
  buyerAgent: "Buyer's Agent",
  foreclosure: 'Foreclosure',
  shortSale: 'Short-Sale',
  consulting: 'Consulting',
  relocation: 'Relocation',
  commercialRealEstate: 'Commercial R.E.',
}

interface ISpecialty {
  onShowMoreTextReady?: (v: number) => void
}

export const Specialty: React.FC<ISpecialty> = ({ onShowMoreTextReady }) => {
  const agentSpecialty = useSelector(selectAgentSpecialty)
  const { specialties = {}, serviceAreas = [], languages = [] } = agentSpecialty

  const agentSpecialties = pickBy(specialties, Boolean)
  const hasSpecialty = !isEmpty(agentSpecialties)
  const hasServiceArea = !isEmpty(serviceAreas)
  const hasLanguage = !isEmpty(languages)
  if (!hasSpecialty && !hasServiceArea && !hasLanguage) return null
  return (
    <Card className="content-card-container" variant="outlined">
      <CardElement>
        <Typography variant="title-medium" fontWeight="bold">
          {TITLE}
        </Typography>
        <ShowMoreText
          line={9}
          lineOffset={8}
          mLine={13}
          mLineOffset={11}
          $mt="24"
          $mMargin="0"
          onReady={onShowMoreTextReady}
        >
          <Grid container spacing={2}>
            {hasSpecialty && (
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Typography className="typography-title-small" variant="body-large" component="div">
                  Agent specialties
                </Typography>
                <Typography variant="body-large" component="ul">
                  {Object.keys(agentSpecialties).map((specialty: string) => (
                    <Typography variant="body-large" component="li" key={specialty}>
                      {specialty === 'other'
                        ? agentSpecialties[specialty]
                        : SPECIALTIES_LABEL[specialty]}
                    </Typography>
                  ))}
                </Typography>
              </Grid>
            )}
            {hasServiceArea && (
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Typography className="typography-title-small" variant="body-large" component="div">
                  Service areas
                </Typography>
                <Typography variant="body-large" component="ul">
                  {serviceAreas.map((serviceArea: string) => (
                    <Typography variant="body-large" component="li" key={serviceArea}>
                      {serviceArea}
                    </Typography>
                  ))}
                </Typography>
              </Grid>
            )}
            {hasLanguage && (
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <Typography className="typography-title-small" variant="body-large" component="div">
                  Language
                </Typography>
                <Typography variant="body-large" component="ul">
                  {languages.map((language: string) => (
                    <Typography variant="body-large" component="li" key={language}>
                      {language}
                    </Typography>
                  ))}
                </Typography>
              </Grid>
            )}
          </Grid>
        </ShowMoreText>
      </CardElement>
    </Card>
  )
}
